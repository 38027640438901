<template>
  <div>
    <v-form class="text-center" autocomplete="on" @submit.prevent="signIn">
      <v-text-field
        id="email"
        type="text"
        name="email"
        autocomplete="username"
        v-model.trim="credentials.username"
        :placeholder="$t('general.email_address')"
        :error="$v.credentials.username.$error || signInFailed"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        id="password"
        :type="showPassword ? 'text' : 'password'"
        name="password"
        autocomplete="current-password"
        v-model="credentials.password"
        :placeholder="$t('password.title_and_length')"
        :error="signInFailed"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        outlined
        dense
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-expand-transition>
        <ErrorMessage v-if="errorMessage !== null">
          {{ errorMessage }}
        </ErrorMessage>
      </v-expand-transition>

      <v-btn type="submit" color="primary" width="100%" :loading="isLoggingIn">
        {{ $t('login.log_in') }}
      </v-btn>
    </v-form>

    <TwoFactorAuthenticationDialog
      v-model="show2FADialog"
      :profile="profile"
      @successful-verification="signInAfterAuth"
    />
  </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'
import { login } from '@access/services/accessService'
import ErrorMessage from '@/components/ErrorMessage'
import TwoFactorAuthenticationDialog from '@/features/access/components/TwoFactorAuthenticationDialog.vue'

export default {
  name: 'LoginForm',
  components: {
    ErrorMessage,
    TwoFactorAuthenticationDialog,
  },
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      errorMessage: null,
      showPassword: false,
      loginFailed: false,
      loggingIn: false,
      show2FADialog: false,
      profile: {},
    }
  },
  validations: {
    credentials: {
      username: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    isLoggingIn() {
      return this.loggingIn
    },
    signInFailed() {
      return this.loginFailed
    },
  },
  created() {
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email
    }
  },
  methods: {
    signIn() {
      this.loggingIn = true
      this.loginFailed = false

      this.$v.credentials.$touch()
      if (this.$v.credentials.$error) {
        this.errorMessage = this.$t('login.credentials_invalid')
        this.loginFailed = true
        this.loggingIn = false

        return
      }

      login({ credentials: this.credentials })
        .then((resp) => {
          const urlBeforeLogin = this.$route.query.redirect_to
          const profile = resp.data

          if (profile.enable2fa) {
            this.profile = resp.data
            this.show2FADialog = true

            return
          }

          localStorage.setItem('token', profile?.access_token)
          localStorage.setItem('refreshToken', profile?.refresh_token)

          window.dataLayer.push({
            event: 'login',
            category: 'user',
            action: 'login',
            label: 'successful',
            user_id: profile.email,
          })

          this.$store.commit('resetProfile')

          if (
            urlBeforeLogin &&
            -1 === urlBeforeLogin.indexOf('/login') &&
            0 === urlBeforeLogin.indexOf(window.location.origin)
          ) {
            window.location.href = urlBeforeLogin
          } else if (profile?.active_workspace_id) {
            this.$router.push({
              name: 'dashboard',
              params: {
                workspace_id: profile?.active_workspace_id,
              },
            })
          } else {
            this.$router.push({
              name: 'dashboard',
            })
          }
        })
        .catch((err) => {
          if (400 === err.code) {
            this.errorMessage = this.$t('login.email_uncofirmed')
            this.$notification.error(this.$t('login.email_uncofirmed'))

            return this.$router.push({
              name: 'registerToConfirm',
              params: {
                email: this.user.email,
              },
            })
          }

          this.loginFailed = true
          this.errorMessage = this.$t('login.credentials_invalid.short')
          this.$notification.error(this.$t('login.credentials_invalid.short'))
        })
        .finally(() => {
          this.loggingIn = false
        })
    },
    signInAfterAuth() {
      const urlBeforeLogin = this.$route.query.redirect_to

      localStorage.setItem('token', this.profile?.access_token)
      localStorage.setItem('refreshToken', this.profile?.refresh_token)
      window.dataLayer.push({
        event: 'login',
        category: 'user',
        action: 'login',
        label: 'successful',
        user_id: this.profile.email,
      })

      this.$store.commit('resetProfile')

      if (
        urlBeforeLogin &&
        -1 === urlBeforeLogin.indexOf('/login') &&
        0 === urlBeforeLogin.indexOf(window.location.origin)
      ) {
        window.location.href = urlBeforeLogin
      } else if (this.profile?.active_workspace_id) {
        this.$router.push({
          name: 'dashboard',
          params: {
            workspace_id: this.profile?.active_workspace_id,
          },
        })
      } else {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
